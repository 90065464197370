import React, {useState} from "react";
import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import { Link } from 'gatsby';
import Iframe from 'react-iframe';
import { Title, Testonimal, Twitter } from "../../components/Globals";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';
import "./../services/awesome-slider-override.css";
import { FaAngleDoubleDown } from "react-icons/fa";
const MultisoftConsultingPage = () => {

    const ref = React.createRef();

    const arrowClick = () =>
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
  return (
        <Layout>
         
            <div className="infographics-background flex flex-col">
            <Helmet>
                <title>Salesforce report: 93% of Indian customers expect faster service as technology advances</title>
                <meta name="description" content="Salesforce report: 93% of customers in India expect faster service as technology advances."/>
                <meta name="keywords" content="salesforce report,salesforce india report, salesforce AI"/>
                <link rel="canonical" href="https://www.cymetrixsoft.com/infographics/salesforce-report-indian-customers/" />
            </Helmet>
            <h1 className=" xl:text-4xl xl:m-56  md:m-16 sm:m-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
                text-white text-uppercase text-center">
                    Salesforce report: 93% of customers in India expect faster service as technology advances
                </h1>
                <button class="focus:outline-none " >
                <FaAngleDoubleDown className=" w-32 h-20 py-5 text-white" onClick={arrowClick}/>
                </button>
            </div>
            
    


        <div className="relative max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24">
              <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-left">
              
              <p className="font-hairline text-justify">The way customers in India think and behave while making a purchase is being transformed by the advancements in technology, particularly the rise of Artificial Intelligence. According to a recently published report by Salesforce, which surveyed 14,300 consumers and business buyers from 25 countries, including 650 respondents from India, several intriguing insights have been derived from this report.				
              </p><br/>
              <p className="font-hairline text-justify">At Cymetrix, a leading <Link to="/services/salesforce-consulting/" class="underline text-blue-700"> Salesforce Partner</Link>, we have summarized the key highlights here:				
              </p><br/>
              </div>
              <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
              <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                  <img
                      className="border-8 border-solid"
                      src={require('./../../images/infographics/salesforce-india-report-ai.png')}
                      alt="Salesforce Report What Indian Customers Expect As Tech Advances"
                      title="State of connected customer report by Salesforce: Infographic"
                      />
                </div>
              </div>
          </div>
        </div>



            
            <div className="w-full" style={{backgroundColor: '#1e407b'}}>
                <div className="max-w-6xl mx-auto">
                    <p className="text-white sma:px-3">1. The report underscores the impact of economic and technological shifts on customer priorities, behaviors, and expectations. In India, 76% of customers expect companies to anticipate and understand their evolving needs, highlighting the increasing demand for personalized experiences.</p><br/>
                    <p className="text-white sma:px-3">2. Another important insight from the report is that 93% of customers in India expect faster service as technology advances. With the widespread adoption of smartphones and other digital devices, customers have grown accustomed to faster service. Brands that fail to meet their expectations risk losing customers to competitors who provide swift and more efficient service.</p><br/>
                    <p className="text-white sma:px-3">3. Apart from this, the report also highlights that 90% of consumers in India say the experience a company provides is just as essential as its products and services. That shows customers look for more than just a good product or service. They want an experience that is engaging, convenient, and enjoyable.</p><br/>
                    <p className="text-white sma:px-3">4. Lastly, we also discovered that 63% of consumers in India switched brands at least once in the past year. That shows that customers are willing to switch brands if their expectations are not met. With the rise of e-commerce and online shopping, customers can access a wide range of options, making it easier to switch brands if dissatisfied.</p><br/>
                    <p className="text-white sma:px-3">In conclusion, the State of the Connected Customer report by Salesforce highlights how AI, digital transformation, and macroeconomic trends are reshaping customer expectations and behaviors across the buying lifecycle in India. Moreover, brands that deliver personalized experiences, faster service, and a seamless customer experience can differentiate themselves from their competitors and build long-term customer loyalty.</p><br/>
                    
                   
                </div>
            </div>
         

            <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
                <h1 className="mt-5 text-xl font-semibold">From Our Blogs</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-partners-license-types.png')} alt="Salesforce partners for licencing | Cymterix Software" title="Salesforce license types" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/11/02/understanding-salesforce-license-types-for-optimization-and-cost-reduction/" >Understanding Salesforce License Types For Optimization and Cost Reduction</Link></h1>
                            <p className="text-lg">Read about the core attributes of Salesforce.com licensing agreements.</p>
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-einstien-gpt-ai.png')} alt="Salesforce Einstien GPT | Cymetrix Software" title="Why Salesforce generative AI is the next big thing?" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/11/07/salesforce-einstein-gpt-why-generative-ai-is-the-next-big-thing/">Salesforce Einstein GPT: Why Generative AI is the next big thing?</Link></h1>
                            <p className="text-lg">Read about how Salesforce Einstien is revolutionizing the way CRM works.</p>
                        </div>
                    </div>
                </div>
            </div>




        </Layout>
    );
}

export default MultisoftConsultingPage;